import { CURRENCY_PLACEHOLDER_REGEX, FORMATS } from "../constants";
import type AmountFormatterInterface from "./AmountFormatterInterface";

class AmountFormatter implements AmountFormatterInterface {
    constructor(private readonly money_format: string) {}
    private amountFormat(format_string: string, amount: number): string {
        const match = format_string.match(CURRENCY_PLACEHOLDER_REGEX);

        if (!match) {
            throw new Error();
        }
        const format = match[1];

        if (Object.keys(FORMATS).includes(format)) {
            return FORMATS[format as keyof typeof FORMATS](amount);
        } else {
            throw new Error();
        }
    }

    format(amount: number): string {
        return this.amountFormat(this.money_format, amount);
    }
}

export default AmountFormatter;
