import { globalLoggerToken } from "@/tokens";
import { applyTransaction } from "@datorama/akita";
import {
    Observable,
    Subscription,
    bufferTime,
    combineLatest,
    filter,
    switchMap,
    tap,
} from "rxjs";
import { distinct, map, mergeMap } from "rxjs/operators";
import { inject, injectable } from "tsyringe";

import { LoggerInterface } from "@interfaces/LoggerInterface";

import { ProductsApiServiceInterface } from "../api_services/ProductsApiServiceInterface";
import { ProductsStore } from "../stores/ProductsStore";
import { productsApiServiceToken, productsStoreToken } from "../tokens";

@injectable()
export class ProductsService {
    private bufferedProductIds$: Observable<number[]>;
    private subscription: Subscription | null = null;

    public constructor(
        @inject(productsApiServiceToken)
        private readonly productsApiService: ProductsApiServiceInterface,
        @inject(productsStoreToken)
        private readonly productsStore: ProductsStore,
        @inject(globalLoggerToken) private readonly logger: LoggerInterface
    ) {
        this.bufferedProductIds$ = this.productsStore
            ._select((state) => {
                return state.productStatuses;
            })
            .pipe(
                mergeMap((productStatuses) => productStatuses),
                filter((statusItem) => statusItem.status === "IN_QUEUE"),
                map((statusItem) => statusItem.productId),
                distinct(),
                bufferTime(200)
            );
    }

    public addProductForFetching(id: number) {
        this.logger.debug("ProductsService.addProductForFetching", {
            id,
        });
        this.productsStore.addProductInQueue(id);
    }

    public subscribe() {
        if (this.subscription) return;
        this.logger.debug("ProductsService.subscribe");
        this.subscription = this.bufferedProductIds$
            .pipe(
                filter((ids) => ids.length >= 1),
                tap((ids) => {
                    applyTransaction(() => {
                        for (const id of ids) {
                            this.productsStore.setLoadingStatus(id);
                        }
                    });
                }),
                switchMap((ids: number[]) => {
                    return combineLatest(
                        ids.map((id) => {
                            return this.productsApiService.getProduct(id);
                        })
                    );
                }),
                tap((results) => {
                    applyTransaction(() => {
                        results.forEach((result) => {
                            this.productsStore.add(result);
                            this.productsStore.setReadyStatus(result.id);
                        });
                    });
                })
            )
            .subscribe();
    }

    public stop() {
        this.logger.debug("ProductsService.stop");
        this.subscription?.unsubscribe();
    }
}
