import { Subject } from "rxjs";

import AnalyticsEventsCollectorInterface from "./AnalyticsEventsCollectorInterface";
import { BaseAnalyticsEventInterface } from "./events/BaseAnalyticsEventInterface";

class RxJSAnalyticsEventsCollector
    implements AnalyticsEventsCollectorInterface
{
    events$: Subject<BaseAnalyticsEventInterface>;
    constructor() {
        this.events$ = new Subject();
    }
    pushEvent(event: BaseAnalyticsEventInterface): void {
        this.events$.next(event);
    }
}

export default RxJSAnalyticsEventsCollector;
