import { InjectionToken } from "tsyringe";

import { ProductsApiServiceInterface } from "./api_services/ProductsApiServiceInterface";
import { ProductsQuery } from "./queries/ProductsQuery";
import { ProductsService } from "./services/ProductsService";
import { ProductsStore } from "./stores/ProductsStore";

export const productsApiServiceToken = Symbol(
    "productsApiService"
) as InjectionToken<ProductsApiServiceInterface>;

export const productsStoreToken = Symbol(
    "productsStore"
) as InjectionToken<ProductsStore>;

export const productsServiceToken = Symbol(
    "productsService"
) as InjectionToken<ProductsService>;

export const productsQueryToken = Symbol(
    "productsQuery"
) as InjectionToken<ProductsQuery>;
