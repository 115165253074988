import ApiClientInterface from "@lib/ApiClient/ApiClientInterface";
import { InjectionToken } from "tsyringe";

import { LoggerInterface } from "@interfaces/LoggerInterface";

export const RewardsApplicationConfigToken = Symbol(
    "RewardsApplicationConfigToken"
);

export const rewardsApplicationLoggerToken = Symbol(
    "rewardsApplicationLoggerToken"
) as InjectionToken<LoggerInterface>;

export const rewardsAuthApiClientToken = Symbol(
    "rewardsAuthApiClientToken"
) as InjectionToken<ApiClientInterface>;
