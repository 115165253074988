import { QueryEntity } from "@datorama/akita";
import { injectable } from "tsyringe";

import ReviewsStoreBase, { ReviewsStateBase } from "../stores/ReviewsStoreBase";

@injectable()
class ReviewsQueryBase extends QueryEntity<ReviewsStateBase> {
    constructor(protected store: ReviewsStoreBase) {
        super(store);
    }
    all$ = this.selectAll();
    paginationMeta$ = this.select((v) => v.paginationMeta);
    canLeaveReview$ = this.select((v) => v.canLeaveReview);
    canLeaveReviewMessage$ = this.select((v) => v.canLeaveReviewMessage);
    status$ = this.select((v) => v.status);
    error$ = this.selectError();
    leaveReviewStatus$ = this.select((v) => v.leaveReviewStatus);
    leaveReviewError$ = this.select((v) => v.leaveReviewError);
    canLeaveReviewStatus$ = this.select((v) => v.canLeaveReviewStatus);
}

export default ReviewsQueryBase;
