import { CURRENT_APP_GROWAVE } from "@/constants/current_app";
import {
    commonAuthApiClientToken,
    globalLoggerToken,
    gwStorefrontAppInfoToken,
    localizationInfoProviderToken,
} from "@/tokens";
import { GraphQLApiClient } from "@lib/GraphQLApiClient/GraphQLApiClient";
import { DependencyContainer, instanceCachingFactory } from "tsyringe";

import { GrowaveProductsApiService } from "./api_services/GrowaveProductsApiService";
import { GrowaveProductsLiquidApiService } from "./api_services/GrowaveProductsLiquidApiService";
import { ShopifyGraphQLProductsApiService } from "./api_services/ShopifyGraphQLProductsApiService";
import { ProductsQuery } from "./queries/ProductsQuery";
import { ProductsService } from "./services/ProductsService";
import { ProductsStore } from "./stores/ProductsStore";
import {
    productsApiServiceToken,
    productsQueryToken,
    productsServiceToken,
    productsStoreToken,
} from "./tokens";

export class ProductsModule {
    registerProviders(di: DependencyContainer) {
        di.register(productsApiServiceToken, {
            useFactory: instanceCachingFactory(() => {
                const graphtQLApiClient = di.resolve(GraphQLApiClient);
                const gwStorefrontAppInfo = di.resolve(
                    gwStorefrontAppInfoToken
                );
                const localizationInfoProvider = di.resolve(
                    localizationInfoProviderToken
                );
                if (graphtQLApiClient) {
                    return new ShopifyGraphQLProductsApiService(
                        graphtQLApiClient,
                        localizationInfoProvider.getCountryIsoCode(),
                        localizationInfoProvider.getLanguageIsoCode()
                    );
                }
                if (gwStorefrontAppInfo.appName === CURRENT_APP_GROWAVE) {
                    return new GrowaveProductsApiService(
                        di.resolve(commonAuthApiClientToken)
                    );
                }
                return new GrowaveProductsLiquidApiService(
                    di.resolve(commonAuthApiClientToken)
                );
            }),
        });
        di.register(productsStoreToken, {
            useFactory: instanceCachingFactory(() => {
                return new ProductsStore();
            }),
        });
        di.register(productsQueryToken, {
            useFactory: instanceCachingFactory(() => {
                return new ProductsQuery(di.resolve(productsStoreToken));
            }),
        });
        di.register(productsServiceToken, {
            useFactory: instanceCachingFactory(() => {
                return new ProductsService(
                    di.resolve(productsApiServiceToken),
                    di.resolve(productsStoreToken),
                    di.resolve(globalLoggerToken)
                );
            }),
        });
        di.resolve(productsServiceToken).subscribe();
    }
}
