export * from "./key-codes";
export const EMPTY_STRING = "";
export const DISPLAY_MODE_HOTSPOT_TYPE = "hotspot";
export const DISPLAY_MODE_SHOP_IT_TYPE = "shop_it";
export const CART_PAGE_URL = "/cart";

export const GW_ADDITIONAL_INFO_SELECTOR = "#gw-additional-info";

export enum App {
    Login = "social-login",
    Rewards = "rewards",
    Reviews = "reviews",
    Wishlist = "wishlist",
    TikTok = "tiktok",
    Instagram = "instagram",
    Questions = "questions",
}
