import type AnalyticsEventsCollectorInterface from "@lib/AnalyticsEventsCollector/AnalyticsEventsCollectorInterface";
import { lastValueFrom } from "rxjs";
import { bufferTime, filter } from "rxjs/operators";

import MetricsCollectorApiServiceInteraface from "./AnalyticsEventsCollectorApiServiceInteraface";

const BUFFER_TIME = 3000;

class AnalyticsEventsReporter {
    constructor(
        private readonly collector: AnalyticsEventsCollectorInterface,
        private readonly metricsCollectorApiService: MetricsCollectorApiServiceInteraface
    ) {}
    start(): void {
        this.collector.events$
            .pipe(
                bufferTime(BUFFER_TIME),
                filter((events) => !!events.length)
            )
            .subscribe((metricsEvents) => {
                void lastValueFrom(
                    this.metricsCollectorApiService.sendEvents(metricsEvents)
                );
            });
    }
}

export default AnalyticsEventsReporter;
