import { STATUS } from "@/constants/statuses";
import OffsetPaginationEntity from "@/entities/OffsetPaginationEntity";
import { EntityState, EntityStore, StoreConfig } from "@datorama/akita";
import { injectable } from "tsyringe";

import ReviewEntity from "../entities/ReviewEntity";

export interface ReviewsStateBase extends EntityState<ReviewEntity, number> {
    paginationMeta: OffsetPaginationEntity;
    canLeaveReview: boolean;
    canLeaveReviewMessage: string | null;
    status: STATUS;
    leaveReviewStatus: STATUS;
    leaveReviewError: Error | null;
    canLeaveReviewStatus: STATUS;
}

function createInitialState(): ReviewsStateBase {
    return {
        paginationMeta: {
            perPage: 0,
            totalCount: 0,
            currentOffset: 0,
        },
        canLeaveReview: false,
        canLeaveReviewMessage: null,
        status: STATUS.VIRGIN,
        leaveReviewStatus: STATUS.VIRGIN,
        leaveReviewError: null,
        canLeaveReviewStatus: STATUS.VIRGIN,
    };
}

@injectable()
@StoreConfig({ name: "reviewsStoreBase" })
class ReviewsStoreBase extends EntityStore<ReviewsStateBase> {
    constructor() {
        super(createInitialState());
    }

    setStatus(status: STATUS) {
        this.update(() => ({
            status: status,
        }));
    }

    getStatus(): STATUS {
        return this.getValue().status;
    }

    setLeaveReviewStatus(status: STATUS) {
        this.update(() => ({
            leaveReviewStatus: status,
        }));
    }
}

export default ReviewsStoreBase;
