import { nullable, number, object, string } from "superstruct";

const ReviewProductSchema = object({
    id: number(),
    title: string(),
    vendor: string(),
    handle: string(),
    price: number(),
    comparePrice: nullable(number()),
    imageUrl: nullable(string()),
});

export default ReviewProductSchema;
