import { array, object, string } from "superstruct";

const ReviewImagesSchema = object({
    url: string(),
    thumbnails: array(
        object({
            url: string(),
        })
    ),
});

export default ReviewImagesSchema;
