// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.gw-drawer-placeholder-LC-wrapper {
    position: fixed;
    z-index: var(--gw-z-index-overlay);
    top: 50%;
    left: 0;
    transform: rotate(-90deg) translateX(-50%);
    transform-origin: left top;
}

.gw-drawer-placeholder-RC-wrapper {
    position: fixed;
    z-index: var(--gw-z-index-overlay);
    top: 50%;
    right: 0;
    transform: rotate(90deg) translateX(50%);
    transform-origin: right top;
}

.gw-drawer-placeholder-LC {
    display: flex;
    gap: 50px;
}

.gw-drawer-placeholder-RC {
    display: flex;
    gap: 50px;
    transform: rotate(180deg);
}
`, "",{"version":3,"sources":["webpack://./src/themes/default/drawer-placeholder.css"],"names":[],"mappings":"AAAA;IACI,eAAe;IACf,kCAAkC;IAClC,QAAQ;IACR,OAAO;IACP,0CAA0C;IAC1C,0BAA0B;AAC9B;;AAEA;IACI,eAAe;IACf,kCAAkC;IAClC,QAAQ;IACR,QAAQ;IACR,wCAAwC;IACxC,2BAA2B;AAC/B;;AAEA;IACI,aAAa;IACb,SAAS;AACb;;AAEA;IACI,aAAa;IACb,SAAS;IACT,yBAAyB;AAC7B","sourcesContent":[".gw-drawer-placeholder-LC-wrapper {\n    position: fixed;\n    z-index: var(--gw-z-index-overlay);\n    top: 50%;\n    left: 0;\n    transform: rotate(-90deg) translateX(-50%);\n    transform-origin: left top;\n}\n\n.gw-drawer-placeholder-RC-wrapper {\n    position: fixed;\n    z-index: var(--gw-z-index-overlay);\n    top: 50%;\n    right: 0;\n    transform: rotate(90deg) translateX(50%);\n    transform-origin: right top;\n}\n\n.gw-drawer-placeholder-LC {\n    display: flex;\n    gap: 50px;\n}\n\n.gw-drawer-placeholder-RC {\n    display: flex;\n    gap: 50px;\n    transform: rotate(180deg);\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
