import { commonAuthApiClientToken } from "@/tokens";
import ApiClientInterface from "@lib/ApiClient/ApiClientInterface";
import { Observable, map } from "rxjs";
import { create } from "superstruct";
import { inject, singleton } from "tsyringe";

import ProductEntity from "../entities/ProductEntity";
import { ProductsApiServiceInterface } from "./ProductsApiServiceInterface";
import { GrowaveProductLiquidResponseSchema } from "./responses/GrowaveProductLiquidResponseSchema";

@singleton()
export class GrowaveProductsLiquidApiService
    implements ProductsApiServiceInterface
{
    constructor(
        @inject(commonAuthApiClientToken)
        private readonly apiClient: ApiClientInterface
    ) {}

    public getProduct(id: number): Observable<ProductEntity> {
        return this.apiClient
            .get({
                url: "/getProductLiquid",
                queryParams: {
                    productId: id,
                },
            })
            .pipe(
                map((response) => {
                    const responseData = create(
                        response.body,
                        GrowaveProductLiquidResponseSchema
                    );

                    const featuredImage = responseData.media.find(
                        (media) => media.src === responseData.featured_image
                    );
                    return {
                        id: responseData.id,
                        title: responseData.title,
                        description: responseData.description,
                        featuredImage: featuredImage
                            ? {
                                  id: featuredImage.id,
                                  url: featuredImage.src,
                                  altText: featuredImage.alt,
                              }
                            : null,
                        images: responseData.media
                            .filter((media) => media.media_type === "image")
                            .map((media) => ({
                                id: media.id,
                                url: media.src,
                                altText: media.alt || "",
                            })),
                        handle: responseData.handle,
                        variants: responseData.variants.map((variant) => {
                            return {
                                id: variant.id,
                                title: variant.title,
                                image: variant.featured_image
                                    ? responseData.media.find(
                                          (media) =>
                                              media.id ===
                                              variant.featured_image?.id
                                      )
                                    : null,
                                price: {
                                    amount: variant.price / 100,
                                    currency: null,
                                },
                                compareAtPrice: variant.compare_at_price
                                    ? {
                                          amount:
                                              variant.compare_at_price / 100,
                                          currency: null,
                                      }
                                    : null,
                                quantityAvailable: variant.available
                                    ? Number.POSITIVE_INFINITY
                                    : 0,
                                currentlyNotInStock: variant.available,
                                options: responseData.options.map(
                                    (option, idx) =>
                                        option
                                            ? {
                                                  name: option,
                                                  value: variant[
                                                      `option${
                                                          idx + 1
                                                      }` as "option1"
                                                  ] as string,
                                              }
                                            : null
                                ),
                            };
                        }),
                        options: responseData.options.map((option, idx) => ({
                            name: option,
                            values: responseData.variants.reduce(
                                (acc, variant) => {
                                    const variantOptionValue =
                                        variant[
                                            `option${idx + 1}` as "option1"
                                        ];
                                    if (
                                        variantOptionValue &&
                                        !acc.includes(variantOptionValue)
                                    ) {
                                        return [...acc, variantOptionValue];
                                    }
                                    return acc;
                                },
                                [] as string[]
                            ),
                        })),
                    } as ProductEntity;
                })
            );
    }
}
