import { debounceTime, firstValueFrom, fromEvent, map } from "rxjs";

import ScrollManagerInterface from "./ScrollManagerInterface";

type ScrollManagerItems = Record<
    string,
    { element: HTMLElement; cb?: () => void }
>;

class ScrollManager implements ScrollManagerInterface {
    private items: ScrollManagerItems = {};

    register(name: string, element: HTMLElement, cb?: () => void) {
        if (this.items["name"]) {
            throw new Error("Element with this name is already registered!");
        }

        this.items[name] = { element, cb };
    }

    unregister(name: string) {
        if (!this.items[name]) {
            throw new Error("Element with this name does not exist!");
        }

        delete this.items[name];
    }

    async scrollTo(name: string) {
        if (!this.items[name]) {
            throw new Error("Element with this name does not exist!");
        }

        const item = this.items[name];
        const isFinished = await this.scrollToElementRef(
            item.element,
            { behavior: "smooth" },
            true
        );
        isFinished && item.cb && item.cb();

        return isFinished;
    }

    private scrollToElementRef(
        element: HTMLElement,
        options?: ScrollIntoViewOptions,
        emitFinish = false
    ): void | Promise<boolean> {
        element.scrollIntoView(options);
        if (emitFinish) {
            return firstValueFrom(
                fromEvent(window, "scroll").pipe(
                    debounceTime(100),
                    map(() => true)
                )
            );
        }
    }

    public getItems(): ScrollManagerItems {
        return this.items;
    }
}

export default ScrollManager;
