import { STATUS } from "@/constants/statuses";
import { QueryEntity } from "@datorama/akita";
import { map } from "rxjs/operators";
import { injectable } from "tsyringe";

import { ProductsState, ProductsStore } from "../stores/ProductsStore";

@injectable()
export class ProductsQuery extends QueryEntity<ProductsState> {
    public constructor(private readonly productsStore: ProductsStore) {
        super(productsStore);
    }

    public selectProductInLoadingStatus(productId: number) {
        return this.select((state) => state.productStatuses).pipe(
            map((statusItems) => {
                return (
                    statusItems.find(
                        (statusItem) => statusItem.productId === productId
                    )?.status === STATUS.LOADING
                );
            })
        );
    }

    public selectProductInQueueStatus(productId: number) {
        return this.select(
            (state) =>
                state.productStatuses.find(
                    (statusItem) => statusItem.productId === productId
                )?.status
        );
    }

    public selectProduct(productId: number) {
        return this.selectAll().pipe(
            map((products) =>
                products.find((product) => product.id === productId)
            ),
            map((product) => product || null)
        );
    }

    public selectProductAndVariant(
        productId: number,
        variantId: number | null
    ) {
        return this.selectAll().pipe(
            map((products) =>
                products.find((product) => product.id === productId)
            ),
            map((product) => ({
                product: product,
                variant: product?.variants.find((variant) => {
                    if (!variantId) {
                        return true;
                    }
                    return variant.id === variantId;
                }),
            }))
        );
    }
}
